import React from "react";

const SectionTwo = () => {
  return (
    <>
      <section className="module parallax parallax-3" id="section2">
        <div className="container-fluid">
          <div className="content row">
            {/* left side */}
            <div className="col-md-8 left">
              <div className="marg-top">
              <div className="justify-content">
                <h4 className="justify-content">
                Website 1
                </h4>
                <p className="justify-content">
                  Hermoso diseño elegante que posee carrusel de imágenes
                  responsive, controles deslizantes con indicadores y
                  subtítulos; en la parte superior de la página. Desarrollada
                  con tecnologías como #javascript #reactjs #sass Ésta landing
                  page posee:
                  <li>Integración con Redes Sociales </li>
                  <li> Integración con WhatsApp</li>
                  <li>Carrusel de imágenes</li>
                  <li>Barra de Navegación</li>
                  <li>Formulario de contacto</li>
                  <li>Diseño Responsive. Se adapta a dispositivos móviles</li>
                 {/*  <li>Configuración, gratis</li>
                  <li>Registro de Dominio, gratis por 1 año</li>
                  <li>2 semanas de soporte, gratis</li>
                  Puede solicitar cambio de color, sin costo alguno. */}
                </p>
              </div>
              <a
                href="https://cyantechsolutions-landing-page-one.netlify.app/"
                rel="noreferrer"
                role="button"
                target="_blank"
              >
                {" "}
                <button className="btn btn-info btn-section2">Demo</button>
              </a>
            </div>
            </div>
          </div>
          {/* right side */}
         {/*  <div className="col-sm-12">
            <div className="wrapper">
              <h1 className="right">Súper Promo!</h1>
              <div className="right-col">
                <p className="price">LLévala por tan sólo</p>
                <p className="price"> $990 BRL o $190 USD</p>
                <br />
                <button className="btn btn-mercado-pago">
                  <a
                    href="https://mpago.li/2WXHsmb"  
                    rel="noreferrer"
                    target="_blank"
                    className="btn-a"
                  >
                    {" "}
                    Mercado Pago
                  </a>
                </button>
                <button className="btn btn-paypal">
                  <a
                    href="https://paypal.me/cyantechsolutionslp1?country.x=CO&locale.x=es_XC"
                    rel="noreferrer"
                    target="_blank"
                    className="btn-a"
                  >
                    {" "}
                    PayPal
                  </a>
                </button>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};
export default SectionTwo;

/* paypal personal @cyan323 */
/* https://paypal.me/cyan323?country.x=CO&locale.x=es_XC */