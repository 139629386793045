import React from "react";
//import { ImWhatsapp } from "react-icons/im";

const ContactForm = () => {
  return (
    <>
     <main className="mt-5" id='contact-form'>
      <div className="container-fluid  justify-content-center align-items-center">
      <section className="mb-5">
        <div className="row d-flex">
        <p className="text-center-mobile">¿Listo para empezar? ¡Envíanos un mensaje ahora!</p>

          <div className="col-12 col-md-6">
            <form
              action="https://formspree.io/f/mbjwaevy"
              method="POST"
              className="contact-form align-items-center"
            >
              <div className="row mb-4 group">
                <div className="col">
                <input
                  required=""
                  type="text"
                  className="input"
                  id="form1"
                  name="name"
                />
                
                <span className="input-border"></span>
                {/*  <span className="bar-form"></span> */}
                <label>Nombre</label>
              </div>
              </div>
              <br />

              <div className="row mb-4 group">
              <div className="col">
                <input
                  required=""
                  type="email"
                  className="input"
                  id="typeEmail"
                  name="email"
                />
                <span className="input-border"></span>
                {/*  <span className="bar-form"></span> */}
                <label>Email</label>
              </div>
              </div>
              <br />

              <div className="row mb-4 group">
              <div className="col">
                <input
                  required=""
                  type="text"
                  className="input"
                  id="textAreaExample"
                  name="message"
                />
                <span className="input-border"></span>
                {/*  <span className="bar-form"></span> */}
                <label>Mensaje</label>
              </div>
              </div>
              <br />
              <button type="submit" className="btn btn-submit justify-content-center">
                Enviar mensaje
              </button>
            </form>
          </div>
        </div>
        </section>
      </div>
      <br />
      <button className="top"><a href='#top' className="top-a">⬆</a></button>

      </main>
     
      {/* <a
        href="https://wa.me/573124537826"
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ImWhatsapp className="whatsapp-icon" />
      </a> */}
    </>
  );
};

export default ContactForm;