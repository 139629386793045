import React, { useState } from "react";
import {
  FaInstagram,
  FaFacebook,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";

const Footer = () => {
  const [showAboutUs, setShowAboutUs] = useState(false);

  const toggleAboutUs = () => {
    setShowAboutUs(!showAboutUs);
  };

  return (
    <footer className="footer">
      <h5 className="title-footer">Cyan Tech Solutions</h5>
      <div id="social-icons">
        <a
          href="https://www.instagram.com/cyan.tech.solutions/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram className="footer__social-icon" />
        </a>
        <a
          href="https://www.facebook.com/cyantechsolutions/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebook className="footer__social-icon" />
        </a>
      </div>

      <div className="divider"></div>

      <div className="footer-container">
        <div className="footer-column">
        <div className="footer-column__text-container">

        <p className="footer-column__text">Consultas, dudas:</p>
          <p className="footer-column__text">Email: support@cyantechsolutions.com</p>
          <p className="footer-column__text">
          © 2024 - Cyan Tech Solutions - Todos los derechos reservados.
          </p>
        </div>
        </div>
        <div
          className="about-us-footer"
          onClick={toggleAboutUs}
        >
          <h5>About Us</h5>
          {" "}
          {showAboutUs ? (
            <FaChevronUp className="chevron-icon" />
          ) : (
            <FaChevronDown className="chevron-icon" />
          )}
        </div>
        {showAboutUs && (
          <div className="about-us-content">
            <div className="about-us-column">
              <p>
                En <strong>Cyan Tech Solutions</strong>, nos especializamos en
                transformar ideas en soluciones tecnológicas efectivas. Nuestro
                equipo desarrolla aplicaciones web, tiendas en línea, landing
                pages y sitios web que se destacan tanto por su modernidad como
                por su elegancia.
              </p>
              <p>
                Con sede en el estado de Paraná, Brasil, estamos comprometidos
                en proporcionar servicios tecnológicos de vanguardia.
              </p>
              <p>
                Nuestra misión es ofrecer a nuestros clientes soluciones
                tecnológicas innovadoras que impulsen su éxito en un mundo
                digital en constante cambio. Creemos en la creatividad, la
                excelencia técnica y el compromiso con la satisfacción del
                cliente.
              </p>
            </div>
            <div className="about-us-column">
              <h5>Expertise & Technologies</h5>
              <p>
                Somos expertos en utilizar tecnologías de última generación para
                crear soluciones personalizadas que se adaptan a sus
                necesidades. Nuestras habilidades clave nos permiten ofrecer
                soluciones seguras y confiables:
              </p>
              <p>
                <strong>Seguridad y Protección de Datos:</strong>
              </p>
              <ul>
                <li>
                  Implementación de sistemas robustos de autenticación y
                  autorización.
                </li>
                <li>
                  Protección contra amenazas comunes, como la inyección de SQL y
                  el scripting entre sitios (XSS).
                </li>
              </ul>
              <p>
                Estas y otras competencias nos permiten desarrollar soluciones
                que no solo cumplen con sus requisitos, sino que también
                garantizan la seguridad y protección de sus datos.
              </p>
            </div>
          </div>
        )}
      </div>
    </footer>
  );
};

export default Footer;
