import React from 'react'
import Header from './components/Header/Header'
import SectionOne from './components/SectionOne/SectionOne';
import SectionThree from './components/SectionThree/SectionThree';
import SectionTwo from './components/SectionTwo/SectionTwo';
import SectionFour from './components/SectionFour/SectionFour';
import SectionFive from './components/SectionFive/SectionFive';
import SectionSix from './components/SectionSix/SectionSix';
import SectionSeven from './components/SectionSeven/SectionSeven';
import SectionEight from './components/SectionEight/SectionEight';
import SectionNine from './components/SectionNine/SectionNine';
import SectionTen from './components/SectionTen/SectionTen';
import SectionEleven from './components/SectionEleven/SectionEleven';
import SectionTwelve from './components/SectionTwelve/SectionTwelve';
import SectionThirteen from './components/SectionThirteen/SectionThirteen';
import SectionFourteen from './components/SectionFourteen/SectionFourteen';
import ContactForm from './components/ContactForm/ContactForm';
import Footer from './components/Footer/Footer';
import "./stylesheets/App.scss";
import "./App.css";
//import 'bootstrap/dist/css/bootstrap.min.css';

//import "bootstrap/dist/css/bootstrap.css";


function App() {

  return (
    <div className="App">
      <Header />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionThirteen />
      <SectionFourteen />
      <SectionFive />
      <SectionSix />
      <SectionSeven />
      <SectionEight />
      <SectionNine />
      <SectionTen />
      <SectionEleven />
      <SectionTwelve />
      <ContactForm />
      <Footer /> 
    </div>
  )
}

export default App;

