import React from "react";

const SectionFourteen = () => {
  return (
    <>
      <section className="module parallax parallax-15" id="section2">
        <div className="container-fluid">
          <div className="content row">
            {/* left side */}
            <div className="col-md-8 left">
              <div className="marg-top">
                <div className="justify-content">
                  <h4 className="justify-content">e-Commerce Store</h4>
                  <p className="justify-content">
                    Esta elegante e-commerce de moda femenina fue creada
                    utilizando Shopify. El desarrollo del sitio involucró dos
                    componentes clave:
                    <li>
                      Personalización del Tema: Una vez seleccionado el tema,
                      realicé configuraciones y personalizaciones utilizando las
                      opciones integradas del tema. Esto incluyó ajustar
                      colores, tipografías, y disposición de elementos, así como
                      cargar imágenes y contenido específico de la marca, y
                      demás configuraciones como Pasarela de Pago, Facebook
                      Pixel, Google Analytics, Tracking y SEO.
                    </li>
                    <li>
                      Escritura de Código Personalizado: Algunos requisitos
                      específicos de la tienda requerían funcionalidades
                      adicionales que no estaban disponibles de manera
                      predeterminada. Para esto, escribí código personalizado
                      utilizando tecnologías web estándar como HTML, CSS, y
                      JavaScript, así como Liquid, el lenguaje de plantillas de
                      Shopify.
                    </li>
                  </p>
                  {/* <div className="columns">
                    <ul className="column">
                      <li>Barra Superior de Anuncios</li>
                      <li>Menú de Navegación Principal</li>
                      <li>Banner Principal con Efecto Parallax</li>
                      <li>Sección "Queridinhos da Temporada"</li>
                      <li>Favoritos del Momento</li>
                    </ul>
                    <ul className="column">
                      <li>Testimonios de Clientes</li>
                      <li>Sección de Video</li>
                      <li>Suscripción a Noticias</li>
                      <li>Beneficios de Compra</li>
                      <li>Footer Informativo</li>
                    </ul>
                  </div> */}
                </div>
                <a
                  href="https://ipanova.com.br/"
                  rel="noreferrer"
                  role="button"
                  target="_blank"
                >
                  <button className="btn btn-info btn-section2">Demo</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionFourteen;
