import React, { useEffect, useRef, useState } from 'react';
import BIRDS from 'vanta/dist/vanta.birds.min';
import gifLogo from "../../img/logo-gif-unscreen.gif";

const Header = () => {
  const [vantaEffect, setVantaEffect] = useState(null);
  const vantaRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false); // Nuevo estado para controlar la carga del efecto

  useEffect(() => {
    if (!vantaEffect && window.THREE && !isLoaded) {
      setVantaEffect(
        BIRDS({
          el: vantaRef.current,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.00,
          minWidth: 200.00,
          scale: 1.00,
          scaleMobile: 1.00,
          backgroundColor: 0x000000, //negro
          backgroundAlpha: 1.0, // Fondo transparente para el efecto
          color1: 0xff0000,
          color2: 0x00ff00,
          colorMode: 'varianceGradient',
          quantity: 5.00,
          birdSize: 1.00,
          wingSpan: 30.00,
          speedLimit: 5.00,
          separation: 17.00,
          alignment: 10.00,
          cohesion: 20.00
        })
      );
      setIsLoaded(true); // Marcar como cargado después de la inicialización
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect, isLoaded]);

  const handleSmoothScroll = () => {
    const targetId = "section1";
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section className="module parallax parallax-1" id="top" style={{ position: 'relative' }}>
      <div ref={vantaRef} className="vanta-bg"></div>
      <div className="content">
        <img src={gifLogo} alt="elephant cyan tech solutions" className="img-fluid logo" />
        <div className="overlay">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 col-md-offset-2 intro-text">
                <h1 className="title-header">
                  Cyan Tech Solutions
                  <span></span>
                </h1>
                <p className="header-txt">
                  Transformando ideas en Soluciones Tecnológicas
                </p>
                <button type="button" className="btn-header" onClick={handleSmoothScroll}>
                  Comencemos
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
