import React from "react";

const SectionSeven = () => {
  return (
    <>
      <section className="module parallax parallax-9" id="section1">
        <div className="container"></div>
      </section>
    </>
  );
};
export default SectionSeven;
