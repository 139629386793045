import React from "react";

const SectionTwelve = () => {
  return (
    <>
      <section className="module parallax parallax-13" id="section2">
        <div className="container-fluid">
          <div className="content row">
            {/* left side */}
            <div className="col-md-6 left">
              <div className="justify-content">
                {/* Landing Page 6 */}
                <h4 className="justify-content">
                Landing Page 4
                </h4>
                <p className="justify-content">
                  Hermoso diseño jovial que posee un conjunto de tarjetas y
                  formulario de suscripciones. Desarrollada con tecnologías como
                  #javascript #reactjs #sass Ésta landing page posee:
                  <li>Integración con Redes Sociales </li>
                  <li>
                    Integración con MailChimp, para crear listas de audiencias:
                    suscriptores, notificaciones, etc
                  </li>
                  <li>Diseño Responsive. Se adapta a dispositivos móviles</li>
                 {/*  <li>Configuración, gratis</li>
                  <li>Registro de Dominio, gratis por 1 año</li>
                  <li>2 meses de soporte, gratis</li>
                  Puede solicitar cambio de color, sin costo alguno. */}
                </p>
              </div>
              <a
                href="https://cyantechsolutions-lp-sample6.netlify.app/"
                rel="noreferrer"
                role="button"
                target="_blank"
              >
                {" "}
                <button className="btn btn-info btn-section2">Demo</button>
              </a>
            </div>
          </div>
          {/* right side */}
          {/* <div className="col-sm-12">
            <div className="wrapper">
              {/*             <div className="col-md-6 right"> 
              <h1 className="right-12">Súper Promo!</h1>
              <div className="right-col">
                <p className="price">LLévala por tan sólo</p>
                <p className="price">$745.000 COP o $170 USD</p>
                <br />
                <button className="btn btn-mercado-pago">
                  <a href="https://mpago.li/1MbXn24"
                   rel="noreferrer"
                   target="_blank"
                  className="btn-a">
                    {" "}
                    Mercado Pago
                  </a>
                </button>
                <button className="btn btn-paypal">
                  <a
                    href="https://paypal.me/cyantechsolutionslp1?country.x=CO&locale.x=es_XC"
                    rel="noreferrer"
                    target="_blank"
                    className="btn-a"
                  >
                    {" "}
                    PayPal
                  </a>
                </button>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};
export default SectionTwelve;