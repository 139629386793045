import React from "react";

const SectionThree = () => {
  return (
    <>
     <section className="module parallax parallax-5" id="section1">
        <div className="container"></div>
      </section>
  
    
    </>
  );
};
export default SectionThree;

