import React from "react";

const SectionFive = () => {
  return (
    <>
     <section className="module parallax parallax-7" id="section1">
        <div className="container"></div>
      </section>
  
    
    </>
  );
};
export default SectionFive;