import React from "react";

const SectionThirteen = () => {
  return (
    <>
      <section className="module parallax parallax-14" id="section1">
        <div className="container"></div>
      </section>
    </>
  );
};
export default SectionThirteen;

/* <div className="row">
            <h2>
              <strong className="col-md-6 right txt-title-sect1">NULLA PELLENTESQUE AUI</strong>
            <hr id="divider1" />
            </h2>
            <div className="txt-paragraph col-md-6 right">
            <p className="txt-paragraph">
              {" "}
              Dis parturient montes nascetur ridiculus mus mauris vitae
              ultricies. Risus feugiat in ante metus dictum at tempor commodo.
              Orci dapibus ultrices in iaculis nunc sed augue. Mauris augue
              neque gravida in fermentum et sollicitudin. Velit laoreet id donec
              ultrices tincidunt arcu.{" "}
            </p>
            </div>
            </div>
            <button className="btn-sect1">
           <a href="#section2" className="btn-txt-sect1">NEXT</a> 
          </button> */
